
import { Vue, Component, Ref } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { Filter } from "@/cms-services/filter";
import { ListDataSource } from "@/data/List/ListDataSource";
import { isCorrectecTime, required } from "@/cms-services/consts";
import AppForm from "@/components/app-form/app-form.vue";
import DataList from "@/components/common/data/list.vue";
import { mdiPlus } from "@mdi/js";
import ClapprPlayer from "@/components/claapr-player.vue";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import { getValue } from "@/cms-services/helpers";
import moment from "moment";
import Clappr from "clappr";
@Component({
  components: { AppForm, ClapprPlayer, DataEditorDialog },
})
export default class Home extends Vue {
  @Ref("dataList") dataList!: DataList;
  @Ref("appForm") appForm!: AppForm;
  @Ref("dataEditor") dataEditor!: DataEditorDialog;
  @Ref("hiddenVideo") hiddenVideo!: HTMLVideoElement;
  @Ref("player") player!: ClapprPlayer;
  $message: any;
  durationInSecond: number = 0;
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "video",
  });
  icons: any = {
    plus: mdiPlus,
  };
  videoLoaded: boolean = false;
  fields: any = [];

  get caption() {
    return this.dataSource.model?.caption;
  }

  get src() {
    return this.dataSource.model.videoUrl;
  }
  get videoMaxTime() {
    return this.durationInSecond
      ? moment.utc(this.durationInSecond * 1000).format("mm:ss")
      : "00:00";
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Видео",
        to: "/manage/video/",
        exact: true,
      },
      {
        text: this.dataSource.model?.caption,
        disabled: true,
      },
    ];
  }

  get options() {
    return {
      poster: `/api/v1/objectImage/p${this.dataSource.model.defaultImageId}.jpg`,
      watermark: "/logo.svg",
      position: "top-left",
      watermarkLink: "/",
      markersPlugin: {
        markers: [],
        tooltipBottomMargin: 17, // optional
      },
      events: {},
    };
  }

  updateVideoTime(item: any) {
    var time = this.getDurationInSecundes(item.time);

    this.player.updateTime(time);
  }

  async dataItemMounted() {
    await this.$nextTick();
    this.hiddenVideo.addEventListener("loadedmetadata", () => {
      this.durationInSecond = this.hiddenVideo.duration;
      this.fields = [
        {
          editor: "string",
          attrs: {
            label: "Заголовок",
            type: "text",
          },
          grid: { cols: 6 },
          name: "caption",
          validations: [required()],
        },
        {
          editor: "string",
          attrs: {
            label: "Таймкод",
            type: "text",
            mask: "##:##",
          },
          grid: { cols: 6 },
          name: "time",
          validations: [
            required(),
            isCorrectecTime(),
            {
              validator: "maxSecond",
              custom: true,
              value: this.durationInSecond,
              errorMessage: `Для данного видео тайм код не может быть больше чем ${this.videoMaxTime}`,
            },
          ],
        },
        {
          editor: "string",
          attrs: {
            label: "Описание",
            type: "text",
          },
          name: "description",
        },
      ];
      this.videoLoaded = true;
    });
  }

  timeCodeDataSource: ListDataSource = new ListDataSource({
    className: "VideoTimeCode",
    config: {
      pageIndex: 1,
      pageSize: 100,
      filter: JSON.stringify([new Filter("videoId", +this.$route.params.id)]),
    },
  });

  model: any = {
    caption: "",
    description: "",
    time: "",
    videoId: +this.$route.params.id,
  };

  customValidators: any = {
    maxSecond: (field: any, info: any, model: any): Promise<string> => {
      const value = getValue(model, field.name);
      const valid = moment(value, "mm:ss", true).isValid();
      const duration = valid ? this.getDurationInSecundes(value) : null;
      return new Promise<string>((resolve, reject) => {
        duration === null || duration <= info.value
          ? resolve(info?.successMessage)
          : reject(info.errorMessage);
      });
    },
  };
  getDurationInSecundes = (time: string) => {
    return Number(time.split(":")[0]) * 60 + Number(time.split(":")[1]);
  };

  async addTimeCode() {
    const model = await this.dataEditor.update(this.model);
    if (!model) return;

    try {
      await this.timeCodeDataSource.add(model);
      this.$message("Таймкод успешно добавлен");
      if (this.timeCodeDataSource.items.length == 1) {
        await this.dataList.refreshData();
      }
    } catch (error) {
      this.$message("Произошла ошибка", "error");
    }
  }
}
