
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Clappr from "clappr";
import ClapprMarkersPlugin from "clappr-markers-plugin";
import ContextMenuPlugin from "@guzzj/clappr-context-menu-plugin";
import PIPPlugin from "@guzzj/clappr-pip-plugin";
@Component
export default class VidePlayer extends Vue {
  /**
   * Options for the queue plugin. For more details se [plugins docs](https://github.com/joaopaulovieira/clappr-queue-plugin)
   */
  @Prop() markerOptions!: any;

  /**
   * Enable Picture in Picture
   */
  @Prop() allowPip!: boolean;

  /**
   * Used to specify where the player should be attached using a id of one DOM element.
   */
  @Prop({ default: "player" }) id!: string;

  /**
   * Sets media source URL to play. You can set the media source accordingly to existing playbacks.
   */
  @Prop({ required: true }) source!: string | any;

  /**
   * An `Object` with all the options that will go directly for clappr player.
   * Only 5 options don´t work: `parent`, `parentId`, `source`, `events` and `plugins`
   * For more information check [clappr docs](https://github.com/clappr/clappr-core#hammer_and_wrench-configuration)
   */
  @Prop({ default: () => {} }) options: any;

  /**
   * Aspect ratio of the player.
   * It wil check the `width` first and calculate the `height`, if no `width` is set it will do the oposite.
   * Ex. `16 / 9`
   */
  @Prop() aspectRatio!: number;

  /**
   * Height of the player.
   * You can use a number or a string with `px` or `%`
   */
  @Prop() height!: number | string;

  /**
   * Width of the player.
   * You can use a number or a string with `px` or `%`
   */
  @Prop({ default: "100%" }) width!: number | string;

  /**
   * Options for the context menu plugin. For more details se [plugins docs](https://github.com/joaopaulovieira/clappr-context-menu-plugin)
   */
  @Prop() contextMenu: any;

  player: Clappr.player | null = null;
  playerHeight: number | string | null = null;
  playerWidth: number | string | null = null;
  pluginsConfigs: any = {};
  dinamicPlugins: any = [];
  registeredPlugins: any = [];
  plugins: any = {};
  video: HTMLVideoElement | null = null;

  @Watch("source", { deep: true })
  loadSources(source) {
    this.player.load(source);
  }

  mounted() {
    this.mountPlayer();
  }
  created() {
    if (this.allowPip) {
      this.dinamicPlugins.push(PIPPlugin);
    }
    if (this.contextMenu) {
      this.dinamicPlugins.push(ContextMenuPlugin);
      this.pluginsConfigs.contextMenu = this.contextMenu;
      this.registeredPlugins.push("context-menu");
    }
  }

  public updateTime(time: number) {
    if (!this.video) return;
    this.video.currentTime = time;
  }
  async mountPlayer() {
    this.checkSize();
    const options = Object.assign(
      {},
      this.options,
      {
        source: this.source,
        width: this.playerWidth,
        height: this.playerHeight,
        plugins: [ClapprMarkersPlugin, ...this.dinamicPlugins],
        events: {
          ...this.options.events,
          onResize: this.onResize,
        },
        parentId: "#" + this.id,
      },
      this.pluginsConfigs,
    );
    delete options.parent;
    this.player = new Clappr.Player(options);

    this.video = document.querySelector(`#${this.id} video`);
  }
  checkSize() {
    const isPercentage = (string) =>
      typeof string === "string" && string.includes("%");
    const formatSize = (size) =>
      typeof size === "number" ? size + "px" : size;
    this.playerWidth = this.width;
    this.playerHeight = this.height;
    if (this.aspectRatio) {
      if (this.playerWidth) {
        let width: string | number = this.playerWidth;
        if (isPercentage(width)) {
          width = this.$el.clientWidth;
        } else {
          width = parseInt(width.toString());
        }
        this.playerHeight = formatSize(width / this.aspectRatio);
      } else if (this.playerHeight) {
        let height: string | number = this.playerHeight;
        if (isPercentage(height)) {
          height = this.$el.clientHeight;
        } else {
          height = parseInt(height.toString());
        }
        this.playerWidth = formatSize(height * this.aspectRatio);
      }
    }
  }
  onReady() {
    this.$emit("on-ready");
  }
  onError() {
    /**
     * Fired when player receives an error
     */
    this.$emit("error");
  }
  onResize() {
    /**
     * Fired when player resizes
     */
    this.checkSize();
    this.$emit("resize");
  }
  onPlay() {
    /**
     * Fired when player starts to play
     */
    this.$emit("play");
  }
  onPause() {
    /**
     * Fired when player pauses
     */
    this.$emit("pause");
  }
  onStop() {
    /**
     * Fired when player stops
     */
    this.$emit("stop");
  }
  onSeek() {
    /**
     * Fired when player seeks the video
     */
    this.$emit("seek");
  }
  onEnded() {
    /**
     * Fired when player seeks the video
     */
    this.$emit("ended");
  }
  onTimeUpdate() {
    /**
     * Fired when the time is updated on player
     */
    this.$emit("time-update");
  }
  onVolumeUpdate() {
    /**
     * Fired when player updates its volume
     */
    this.$emit("volume-update");
  }
  onSubtitleAvailable() {
    /**
     * Fired when subtitles is available
     */
    this.$emit("subtitle-available");
  }
}
